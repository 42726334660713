<template>
  <div>
    <v-snackbar v-model="showSnackBar" color="primary" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #ffffff">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #ffffff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>

    <v-form v-model="formValid" ref="holyMassForm">
      <v-layout wrap justify-start>
        <v-flex xs11 sm3 md3 lg2 px-0 px-lg-3 px-md-3 px-sm-3  align-self-center> <!-- Adjust size as needed -->
          <v-select
            multiple
            outlined
            dense
            class="text-field"
            :items="daysOfWeek"
            item-value="value"
            item-text="name"
            v-model="selectedDay"
            placeholder="Select a day"
            :rules="[rules.required]"
          ></v-select>
        </v-flex>
    
        <v-divider class="hidden-xs-only" vertical></v-divider> <!-- This adds a vertical line between components -->
    
        <v-flex xs12 sm8 md9 lg10 pl-lg-6 pl-md-10 pl-sm-10> <!-- Adjust size as needed -->
          <v-layout class="pb-2 " wrap v-for="(entry, index) in holymass" :key="entry._id">
            <!-- Time Selection Components remain similar but within their individual flex containers -->
            <v-flex xs11 sm12 md3 lg3 pr-lg-2 pr-md-2 pr-sm-0 pr-0>
              <v-menu v-model="entry.menu" :close-on-content-click="false" :nudge-bottom="40" max-width="300px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="entry.formattedTime"
                    outlined
                    dense
                    class="text-field pt-sm-0 pt-lg-0 pt-md-0 pt-2"
                    placeholder="Select Time"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :rules="[rules.required]"
                  ></v-text-field>
                </template>
                <v-card width="auto">
                  <v-card-text class="time-picker-card-text">
                    <v-layout row wrap>
                      <v-flex xs4>
                        <v-select
                          v-model="entry.selectedHour"
                          :items="hours"
                          label="Hour"
                          solo
                          dense
                          hide-details
                          class="time-picker-select"
                          :rules="[rules.required]"
                          @change="updateFormattedTime(entry)"
                        ></v-select>
                      </v-flex>
                      <v-flex xs4>
                        <v-select
                          v-model="entry.selectedMinute"
                          :items="minutes"
                          label="Minute"
                          solo
                          dense
                          hide-details
                          class="time-picker-select"
                          :rules="[rules.required]"
                          @change="updateFormattedTime(entry)"
                        ></v-select>
                      </v-flex>
                      <v-flex xs4>
                        <v-select
                          v-model="entry.selectedPeriod"
                          :items="periods"
                          label="AM/PM"
                          solo
                          dense
                          hide-details
                          class="time-picker-select"
                          :rules="[rules.required]"
                          @change="updateFormattedTime(entry)"
                        ></v-select>
                      </v-flex>
                    </v-layout>
                  </v-card-text>
                </v-card>
              </v-menu>
            </v-flex>

            <v-flex xs11 sm6 md4 lg4 pr-0 pr-lg-2 pr-sm-2 pr-md-2 pt-sm-0 pt-lg-0 pt-md-0 pt-2>
              <v-select
                outlined
                dense
                :items="riteArray"
                v-model="entry.rite"
                placeholder="Select Rite"
                class="text-field"
                item-text="name"
                item-value="_id"
                :rules="[rules.required]"
              ></v-select>
            </v-flex>
            <v-flex xs11 sm6 md4 lg4 pr-0 pt-sm-0 pt-lg-0 pt-md-0 pt-2>
              <v-select
                outlined
                dense
                :items="languageArray"
                item-text="languageName"
                item-value="_id"
                v-model="entry.language"
                placeholder="Select Language"
                class="text-field"
                :rules="[rules.required]"
              ></v-select>
            </v-flex>
            <v-flex xs10 pl-3 pb-2>
              <v-layout row align-center>
                <span class="live-des mr-2 mt-2">
                  Is a special event held after Holy Mass?
                </span>
                <v-checkbox
                  v-model="entry.isSpecialEventAfterMass"
                  color="primary"
                  hide-details
                  class="mt-2"
                ></v-checkbox>
              </v-layout>
            </v-flex>
            
           
            <v-flex
              v-if="entry.isSpecialEventAfterMass" 
              xs10
              sm11
              md11
              lg11
              pr-lg-2
              pr-md-2
              pr-sm-1
              pt-2
              pt-lg-2
              pt-sm-2
              pt-md-2
            >
            <v-select
            class="pt-2 text-des"
            style="font-size: 14px"
            
            outlined
            dense
            :items="eventArray"
            v-model="entry.holymassEvent"
            item-text="name"
            item-value="name"
            placeholder="Select Events"
            multiple
          ></v-select>
            </v-flex>

            <v-flex xs1 sm1 md1 lg1 align-self-center d-flex justify-center align-center>
              <v-btn 
                class="ml-0"
                icon
                @click="removeEntry(index)"
                color="red"
                small
                v-if="holymass.length > 1"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-flex>


    
            <!-- Other components like Language and Rite selectors remain inside this loop -->
            <!-- Add other components and buttons as required -->
    
          </v-layout>
          <v-flex>
            <v-btn outlined color="blue darken-5" @click="addEntry" small
              ><v-icon> mdi-plus </v-icon>More</v-btn
            >
          </v-flex>
        </v-flex>
      </v-layout>
     

      <v-flex xs12 text-end pb-3>
        <v-btn
          class="ml-16"
          color="blue darken-1"
          @click="submitForm"
          style="font-family: interregular; font-size: 15px; color: white"
          >Add Holy Mass</v-btn
        >
      </v-flex>
      <!-- Include the Add button and Submit form button here -->
    </v-form>
    
  </div>
</template>




<script>
import axios from "axios";
//import AddSpecialMass from './addSpecialMass.vue';
export default {
  // components:{
  //   AddSpecialMass
  // },
  data() {
    return {
      daysOfWeek: [
        { name: "SUN", value: "0" },
        { name: "MON", value: "1" },
        { name: "TUE", value: "2" },
        { name: "WED", value: "3" },
        { name: "THR", value: "4" },
        { name: "FRI", value: "5" },
        { name: "SAT", value: "6" },
      ],
      selectedDay: [],
      isSpecialEventAfterMass: false,
      formValid: false,
      rules: {
        required: (value) => !!value || "This field is required",
      },
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      deletedialog: false,
      dialogdate: false,
      // OccasionDate: "",
      // massName: "",
      hours: Array.from({ length: 12 }, (_, i) =>
        (i + 1).toString().padStart(2, "0")
      ),
      minutes: Array.from({ length: 60 }, (_, i) =>
        i.toString().padStart(2, "0")
      ),
      periods: ["AM", "PM"],
      // edit

      // selectedDay: null,
      holyMassEntries: [],

      // menu2: false,
      isAdding: false,
      expanded: true,
      currentAddType: null,

      // hoursArray: Array.from({ length: 12 }, (_, i) => String(i + 1)), // ['1', '2', ..., '12']
      // minutesArray: Array.from({ length: 60 }, (_, i) =>
      //   String(i).padStart(2, "0")
      // ),
      // periodArray: ["AM", "PM"],

      riteArray: [],
      languageArray: [],
      holymassEvent: [],
      eventArray: [],
      holymass: [
        {
          rite: "",
          language: "",
          selectedHour: "",
          selectedMinute: "",
          selectedPeriod: "",
          // OccasionDate: "",
          // massName: "",

          menu: false,
          holymassEvent: []
        },
      ],
    };
  },

  computed: {
    formattedTime() {
      return `${this.selectedHour}:${this.selectedMinute} ${this.selectedPeriod}`;
    },
    // formattedTime() {
    //   return this.selectedHour && this.selectedMinute && this.selectedPeriod
    //     ? `${this.selectedHour}:${this.selectedMinute} ${this.selectedPeriod}`
    //     : ""; // Placeholder text
    // },
    //   formattedDate() {
    //     // Only format and return the date if one is selected
    //     return this.OccasionDate
    //       ? new Date(this.OccasionDate).toLocaleDateString()
    //       : "";
    //   },
    //   nowDate() {
    //     const now = new Date();
    //     return now.toISOString().substr(0, 10);
    //   },
  },
  mounted() {
    this.getLanguage();
    this.getRite();
    this.getEvent();
    //this.getData();
    // this.setDefaultDay();
  },
  methods: {
    // date
    //   updateDate(entry, date) {
    //     entry.OccasionDate = date;
    //     this.dialogdate = false; // Close the dialog after selecting the date
    //   },
    //   formatDate(date) {
    //     return date ? new Date(date).toLocaleDateString() : "";
    //   },

    addEntry() {
      this.holymass.push({
        rite: "",
        language: "",
        selectedHour: "",
        selectedMinute: "",
        selectedPeriod: "",
        menu: false,
        //   OccasionDate: "",
        formattedTime: "",
        formattedDate: "",
        //   massName: "",
        //   OccasionType: "Special",

        holymassEvent: []
      });
    },

    removeEntry(index) {
      this.holymass.splice(index, 1);
    },

    convertTo24HourFormat(hour, minute, period) {
      let convertedHour = parseInt(hour, 10);

      // Convert 12 AM to 00 (midnight) in 24-hour format
      if (period === "AM" && convertedHour === 12) {
        convertedHour = 0;
      }
      // Convert 12-hour PM time to 24-hour format (except 12 PM)
      else if (period === "PM" && convertedHour !== 12) {
        convertedHour += 12; // Convert to 24-hour format for PM times
      }

      return `${convertedHour.toString().padStart(2, "0")}:${minute
        .toString()
        .padStart(2, "0")}`;
    },

    updateFormattedTime(entry) {
      // This sets the formatted time to display in 12-hour format with AM/PM
      entry.formattedTime = `${entry.selectedHour}:${entry.selectedMinute} ${entry.selectedPeriod}`;
      console.log(entry.formattedTime);
    },

    getEvent() {
      this.appLoading = true;
      axios({
        url: "/get/holymass/events",
        method: "GET",

        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.eventArray = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    getRite() {
      this.appLoading = true;
      axios({
        url: "/get/rite/list",
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.riteArray = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    getLanguage() {
      this.appLoading = true;
      axios({
        url: "/get/language/list",
        method: "GET",

        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.languageArray = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    submitForm() {
      if (this.$refs.holyMassForm.validate()) {
        this.itemadd(); // Your existing method for adding Holy Mass
      } else {
        this.msg = "Please fill out all required fields";
        this.showSnackBar = true;
      }
    },

    itemadd() {
      this.appLoading = true;
      const data = {
        parish: this.$route.query.id,
        daysOfWeek: this.selectedDay,
        holymass: this.holymass.map((entry) => ({
          rite: entry.rite,
          language: entry.language,

          // massName: entry.massName,
          // OccasionDate: entry.OccasionDate,
          // OccasionType: entry.OccasionType,
          startTime: this.convertTo24HourFormat(
            entry.selectedHour,
            entry.selectedMinute,
            entry.selectedPeriod
          ),
          holymassEvent: entry.holymassEvent
        })),
      };

      axios({
        url: "/add/holymasses/for/week",
        method: "POST",
        data: data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.dialog = false;

            // Emit an event to notify parent component
            console.log("Emitting dataAdded event");
            this.$emit("dataAdded", {
              ref: "addWeekly",
              getData: true,
              
            });
            this.expanded = true;
            this.holymass = [
              {
                rite: "",
                language: "",
                selectedHour: "",
                selectedMinute: "",
                selectedPeriod: "",
                //   OccasionDate: "",
                //   massName: "",
                menu: false,
                //OccasionType: "Special",

                holymassEvent: []
              },
              this.selectedDay = [],
            ];
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    // itemadd() {
    //   const data = {
    //     // if(this.holyrite || this.ghh){
    //     //   dayOfWeek:this.holyrite
    //     // }
    //     // assuming you have parishId defined in your data
    //     parish: this.$route.query.id,
    //     //dayOfWeek: this.selectedDay,
    //     holymass: this.holymass.map((entry) => ({
    //       rite: entry.rite,
    //       language: entry.language,
    //       massName: entry.massName,
    //       OccasionDate: entry.OccasionDate,
    //       OccasionType: entry.OccasionType,
    //       // startTime: entry.formattedTime, // Combine time
    //       startTime: this.convertTo24HourFormat(
    //         entry.selectedHour,
    //         entry.selectedMinute,
    //         entry.selectedPeriod
    //       ),
    //       // Include occasionType if applicable
    //     })),
    //   };

    //   axios({
    //     url: "/add/multiple/holymasses",
    //     method: "POST",
    //     data: data,
    //     headers: {
    //       token: localStorage.getItem("token"),
    //     },
    //   })
    //     .then((response) => {
    //       this.appLoading = false;
    //       if (response.data.status) {
    //         this.msg = "Added Successfully";
    //         this.showsnackbar = true;
    //         this.dialog = false;
    //         this.$emit("stepper", {
    //           ref: "addSpecial",
    //           getSpecialData: true,
    //         });
    //         // Clear form fields or reset state
    //         this.OccasionDate = null;
    //         this.holymass = [
    //           {
    //             rite: "",
    //             language: "",
    //             selectedHour: "12",
    //             selectedMinute: "00",
    //             selectedPeriod: "AM",
    //             OccasionDate: "",
    //             massName: "",
    //             menu: false,
    //             OccasionType: "Special",
    //           },
    //         ];

    //       } else {
    //         this.msg = response.data.msg;
    //         this.showsnackbar = true;
    //       }
    //     })
    //     .catch((err) => {
    //       this.appLoading = false;
    //       this.ServerError = true;
    //       console.log(err);
    //     });
    // },

    // formatTimes(startTime) {
    //     return `${this.convertUTCToLocal(startTime)} `;
    //   },
    formatTime(dateTime) {
      // Extract the time part from the string
      const timePart = dateTime.split("T")[1].split("Z")[0];

      // Split time into hours and minutes
      let [hours, minutes] = timePart.split(":");

      // Convert hours to a number
      hours = parseInt(hours);

      // Determine AM/PM
      const ampm = hours >= 12 ? "PM" : "AM";

      // Convert 24-hour time to 12-hour time
      hours = hours % 12;
      hours = hours ? hours : 12; // Adjust 0 to 12

      // Combine hours, minutes, and AM/PM
      const formattedTime = `${hours}:${minutes} ${ampm}`;

      return formattedTime;
    },
  },
};
</script>

<style scoped>
.time-picker-card-text {
  padding: 8px;
  /* Adjust padding inside the card */
}

.time-picker-select {
  width: 100%;
  /* Ensure dropdowns fill column width */
}

.time-picker-card-actions {
  padding: 8px;
  /* Adjust padding in the actions area */
}

.v-menu__content {
  z-index: 1000;
  /* Ensure the menu appears on top */
}

.v-card {
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.text-field {
  font-family: interregular;
  font-size: 14px;
  color: black;
}
</style>